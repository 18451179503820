























import { Component, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardProcessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-process-view-model';

@Component({
  name: 'AddCustomInvestorGoalWizardProcess',
})
export default class AddCustomInvestorGoalWizardProcess extends Vue {
  add_custom_investor_goal_process = Vue.observable(
    new AddCustomInvestorGoalWizardProcessViewModel(),
  );
}
